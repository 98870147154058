<template>
<h1>秘钥管理</h1>
</template>

<script>
    export default {
        name: "secretKey"
    }
</script>

<style scoped>

</style>
